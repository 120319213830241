<template>

  <template v-if="isNonAuthenticatedRoute">
    <div class="wallpaper" />
    
    <router-view />
  </template>
  <template v-else-if="isAuthenticated">
    <div class="wallpaper" />
    <router-view />
  </template>
  <template v-else>
    <Loading title="..." />
  </template>
</template>

<script setup>
import { watch, computed } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { useRoute, useRouter } from 'vue-router'

const $auth0 = useAuth0()
const route = useRoute()
const router = useRouter()

const isAuthenticated = $auth0.isAuthenticated

const isNonAuthenticatedRoute = computed(() => {
  // The check for route.name is critical because for some reason the route starts
  // as an unnamed route of "/" to begin with.
  return (!route.name) || (route.path === '/validation') || (route.path === '/menu-login')
})

watch([$auth0.isAuthenticated, $auth0.isLoading, isNonAuthenticatedRoute], async () => {
  if (isNonAuthenticatedRoute.value) {
    return
  }

  if ($auth0.isAuthenticated.value) {
    return
  }

  if ($auth0.isLoading.value) {
    return
  }


  // This is the new login method that sends folks to our own internal menu
  // in order to faciliate SMS logins.
  router.replace({ path: '/menu-login' })

  // redirect_uri, audience needed
  // window.location = 'https://myaccount.revolutioncompany.com/menu-login?redirect_uri='

  // await $auth0.loginWithRedirect({
  //   authorizationParams: {
  //     redirect_uri: window.location.baseURL
  //   }
  // })
})
</script>

<style lang="css">
@import 'assets/style.css';

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* background-color: var(--bg0); */
  position: relative;
  z-index: 0;
  overflow: hidden;
}

@keyframes MattEffect {
  0% {
    transform: translate(0%, 0%) scale(1.4);
  }

  25% {
    transform: translate(5%, -10%) scale(1.6);
    /* Pan and zoom */
  }

  50% {
    transform: translate(15%, 5%) scale(1.5);
    /* Pan and zoom */
  }

  75% {
    transform: translate(-7%, -8%) scale(2.7);
    /* Pan and zoom */
  }

  100% {
    transform: translate(0%, 0%) scale(1.4);
    /* Return to original position */
  }
}

.wallpaper {
  animation: MattEffect 40s ease-in-out infinite;
  z-index: -1;
  will-change: transform;
  background-image: url('/topography.svg');
  background-color: rgb(12, 22, 21);
  background-repeat: repeat;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* width: 95vw;
  height: 50vh; */
  /* color: var(--fg0); */
  /* object-fit: fill; */
  /* border: none; */
}
</style>
