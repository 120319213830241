<template>
    <div class="top-div">
        <Loading v-if="linking" title="Linking..." />
        <Loading v-if="unlinking" title="Unlinking..." />

        <details>
            <summary v-if="linkedIdentities.length">Your account can be accessed through any linked accounts.</summary>
            <summary v-else="linkedIdentities.length">Select a 3rd party identify service.</summary>
        </details>

        <div class="linked-identities-div">
            <div v-for="i in linkedIdentities" class="identity" :class="{ selected: i.selected }" :key="i.user_id"
                @click="selectIdentity(i)">
                <img class="id-img" v-if="i.picture" :src="i.picture" :alt="i?.profileData.name" />
                
                <div class="identity-name" :title="i?.name">{{ i?.name }}</div>

                <button v-if="i.selected" class='bottom-left icon' title="Unlink this identity" @click="unlinkIdentity(i)">❌</button>
            </div>
        </div>

        <div class="link-buttons">
            <button @click="linkNewGoogleIdentity" class="link">
                <img :src="GoogleImage" title="Google Accounts" />
                <span>Link with Google</span>
            </button>

            <button @click="linkNewAppleIdentity" class="link">
                <img :src="AppleImage" title="Link an Apple identity" />
                <span>Link with Apple</span>
            </button>

            <button @click="linkNewMsIdentity" class="link">
                <img :src="MicrosoftImage" title="Link a Microsoft identity" />
                <span>Link with Microsoft</span>
            </button>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, inject } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { useApi } from "../plugins/api";

const GoogleImage = "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E"
const MicrosoftImage = "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21'%3E%3Cpath fill='%23f25022' d='M1 1h9v9H1z'/%3E%3Cpath fill='%2300a4ef' d='M1 11h9v9H1z'/%3E%3Cpath fill='%237fba00' d='M11 1h9v9h-9z'/%3E%3Cpath fill='%23ffb900' d='M11 11h9v9h-9z'/%3E%3C/svg%3E"
const AppleImage = "data:image/svg+xml;charset=utf-8,%3Csvg width='170' xmlns='http://www.w3.org/2000/svg' height='170'%3E%3Cpath d='M150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-.9 2.61-1.85 5.11-2.86 7.51zM119.11 7.24c0 8.102-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375a25.222 25.222 0 0 1-.188-3.07c0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.311 11.45-8.597 4.62-2.252 8.99-3.497 13.1-3.71.12 1.083.17 2.166.17 3.24z'/%3E%3C/svg%3E"

const ConnectionNames = {
    "google-oauth2": "Google",
    "apple": "Apple",
    "sms": "SMS (Phone)",
    "DeltaPlastics": "Password",
    "windowslive": "Microsoft"
}

const PictureForConnection = {
    "google-oauth2": GoogleImage,
    "apple": AppleImage,
    "sms": '/sms.svg',
    "DeltaPlastics": "Password",
    "windowslive": MicrosoftImage
}

const $auth0 = useAuth0()
const $api = useApi()
const $env = inject("$env")
const $telemetry = inject("$telemetry")

const selectedIdentity = ref(null)
const unlinking = ref(false)
const linking = ref(false)

function selectIdentity(identity) {
    selectedIdentity.value = identity
}
//TODO: if phone# update user info
async function unlinkIdentity(identity) {
    if(! confirm('Unlink Identity?')) {
        return
    }

    unlinking.value = true

    try {
        await $api.deleteIdentity(identity.connection, identity.userId)

        await $auth0.getAccessTokenSilently({ cacheMode: 'off' })
    }
    finally {
        unlinking.value = false
    }
}

const linkedIdentities = computed(() => {
    const rawJson = $auth0?.user?.value?.rawJson || {}

    const identities = rawJson?.identities || []
    const linkedIdentities = identities.filter(i => rawJson.user_id?.indexOf(i.userId) < 0)

    // add some metadata

    linkedIdentities.forEach(i => {
        i.name = i.profileData?.email || i.profileData?.name || i.profileData?.first_name

        i.picture = i.profileData?.picture || PictureForConnection[i.connection]

        i.identifier = i.profileData?.email || i.profileData?.phone_number

        i.selected = selectedIdentity.value?.user_id === i.user_id
    })

    return linkedIdentities
})

const linkedAppleIdentities = computed(() => linkedIdentities.value.filter(i => i.connection === "apple"))
const linkedGoogleIdentities = computed(() => linkedIdentities.value.filter(i => i.connection === "google-oauth2"))

class DummyCache {
    constructor() {
        this.vals = {}
    }
    set(key, entry) {
        this.vals[key] = entry
    }
    get(key) {
        return this.vals[key]
    }
    remove(key) {
        delete this.vals[key]
    }
    allKeys() {
        return Object.keys(this.vals)
    }
}

async function linkNewGoogleIdentity() {
    return await linkNewIdentity("google-oauth2")
}

async function linkNewAppleIdentity() {
    return await linkNewIdentity("apple")
}

async function linkNewMsIdentity() {
    return await linkNewIdentity("windowslive")
}

async function linkNewIdentity(connection) {
    try {
        linking.value = true

        const $linkedAuth0 = await createAuth0Client({
            domain: $env.VUE_APP_AUTH0_DOMAIN,
            clientId: $env.VUE_APP_AUTH0_CLIENT_ID,
            cache: new DummyCache(),
            legacySameSiteCookie: false,
            useCookiesForTransactions: false,
            useRefreshTokens: false
        })

        await $linkedAuth0.loginWithPopup({
            authorizationParams: {
                audience: $env.VUE_APP_AUTH0_API_AUDIENCE,
                connection,
                prompt: 'login',
                "rev-linking": "true"
            }
        })

        const token = await $linkedAuth0.getTokenSilently({
            authorizationParams: {
                audience: $env.VUE_APP_AUTH0_API_AUDIENCE
            }
        })

        await $api.postIdentity(token)

        await $auth0.getAccessTokenSilently({ cacheMode: 'off' })
    } catch (e) {
        if (e?.error === "cancelled") {
            return
        }

        $telemetry.error(e);
    }
    finally {
        linking.value = false
    }
}
</script>

<style lang="css" scoped>
.top-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    max-width: 32rem;

}

fieldset {
    width: 100%;
    text-overflow: auto;
    text-wrap: wrap;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: calc(2 * var(--padding));
    row-gap: var(--padding);
    animation: FadeIn 0.35s ease-in-out;
    /* border: 2px solid var(--bg2); */
    padding: var(--padding);
    border-top: 1px solid var(--bg2);
    border-left: none;
    border-right: none;
    border-bottom: none;
}

fieldset legend {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    column-gap: var(--padding);
}

fieldset legend img {
    width: 2rem;
    height: 2rem;
}

.linked-identities-div {
    max-width: 100%;
    text-overflow: auto;
    text-wrap: wrap;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: calc(2 * var(--padding));
    row-gap: calc(2 * var(--padding));
    animation: FadeIn 0.35s ease-in-out;
    padding: calc(4 * var(--padding));
}

.identity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4.5rem;
    padding: 1.25rem;
    width: 6rem;
    height: 6rem;
    background: transparent;
    border: 3px solid var(--bg2);
    cursor: pointer;
    color: var(--fg1);
    position: relative;
}

.identity.selected {
    color: var(--fg0);
    border: 3px solid var(--fg0);
}

.identity-name {
    max-width: 6rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.id-img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.bottom-left {
    position: absolute;
    left: 0px;
    bottom: 0px;
}

.identity button.icon {
    border-radius: 4rem;
    padding: 0px;
    width: 2rem !important;
    height: 2rem !important;
    opacity: 0.0;

}

.identity.selected button.icon {
    opacity: 1.0;
    padding: 0px;
    width: 2rem !important;
    height: 2rem !important;
    animation: FadeIn 0.35s ease-in-out;
}

.identity button.icon:hover {
    border-radius: 4rem;

    border: 2px solid var(--fg0);
}

.link-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: var(--padding);
    column-gap: var(--padding);
}

button.link {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: var(--padding);
    background: var(--fg1);
    color: var(--bg0);
    border: 2px solid var(--bg0);
    font-weight: bold;
    padding-left: 0.5em;
    padding-right: 0.5em;
    min-width: 15em;
}

button.link img {
    width: 1.5em;
    height: 1.5em;

    margin-right: 0.5em;

}
</style>