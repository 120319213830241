<template>
  <DealerSearch v-model:dealer-id="selectedDealer.id" :handleSubmit="handleSubmit" />
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useApi } from "../plugins/api";
import DealerSearch from "../components/DealerSearch.vue";

const api = useApi();

const myDealer = ref(null);

const getMyDealer = async () => {
  try {
    const { userInformation } = await api.getUserInformation();

    myDealer.value = {
      firstName: userInformation.first_name,
      lastName: userInformation.last_name,
      address: userInformation.address1,
      zip: userInformation.zip,
      city: userInformation.city,
      state: userInformation.state,
      phone: userInformation.phone,
      org: userInformation.organization,
      dealerId: userInformation.dealer_id,
    };
  } catch (e) {
    console.error(e);
  }
};

onMounted(getMyDealer);

const selectedDealer = computed(() => ({
  id: myDealer.value?.dealerId ?? null,
}));

const handleSubmit = async () => {
  try {
    if (selectedDealer.value.id !== myDealer.value?.id || !myDealer.value) {
      const userData = {
        firstName: myDealer.value.firstName,
        lastName: myDealer.value.lastName,
        address: myDealer.value.address,
        zip: myDealer.value.zip,
        city: myDealer.value.city,
        state: myDealer.value.state,
        phone: myDealer.value.phone,
        org: myDealer.value.org,
        dealerId: selectedDealer.value.id,
      };
      await api.putUserInformation(userData);
      myDealer.value = {
        ...myDealer.value,
        dealerId: selectedDealer.value.id,
      };
    }
  } catch (e) {
    console.error(e);
  }
};
</script>

<style lang="css" scoped>
</style>
