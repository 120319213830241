<template>
  <div class="home-top-div">
    <nav>
      <div>
        <router-link :to="{ path: '/', query: callbackQuery }">Home</router-link>
        <router-link v-if="nestedRoute" :to="nestedRoute.path">
          /{{ nestedRoute.name }}
        </router-link>
      </div>

      <span v-if="!callbackUrl" class="right">
        <router-link to="/logout">Logout</router-link>
      </span>

      <span v-if="callbackUrl" class="mobile-back-bttn">
        <button @click="exitInAppBrowser">
          <span class="material-icons">arrow_back</span>Back To App
        </button>
      </span>
    </nav>

    <CompanyLogo class="company-logo"/>

    <div v-if="!isNested" class="profile-summary" :class="{ nested: isNested }">
      <img v-if="rawJson?.picture" :src="rawJson.picture" alt="Profile picture" />

      <div v-if="!isNested">{{ rawJson.nickname }}</div>

      <template v-if="isNested">
        <span class="ml-1">
          <router-link to="/">Back</router-link>
        </span>
      </template>
    </div>

    <template v-if="!isNested">
      <div class="profile-buttons">
        <button @click="gotoContact">Contact Info</button>

        <button @click="gotoDealer">Dealer</button>

        <button @click="gotoIdentities">Linked Accounts</button>

        <template v-if="isAdmin">
          <button @click="gotoValidationAdmin" class="admin">Validation Admin</button>

          <button @click="gotoAuth0Admin" class="admin">Auth0 Admin</button>
        </template>
      </div>
    </template>
    <template v-else>
      <div style="flex-grow: 1">
        <router-view />
      </div>
    </template>

    <OtherAccountOptions v-if="!isNested" :verificationValue="verificationValue" style="align-self: stretch;"/>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter, useRoute } from "vue-router";
import OtherAccountOptions from "../components/OtherAccountOptions.vue";
import CompanyLogo from "../components/CompanyLogo.vue";

const ROLES_URL = `https://deltaplastics.com/roles`;

const $auth0 = useAuth0();
const isAdmin = computed(() =>
  $auth0?.user?.value[ROLES_URL]?.includes("MyAccount Admin")
);

const rawJson = computed(() => $auth0?.user?.value?.rawJson || {});
const verificationValue = computed(() => {
  const isMobileUser = $auth0?.user?.value?.rawJson?.user_id.includes("sms");
  if (isMobileUser) {
    return rawJson.value?.phone_number.slice(2);
  }
  return rawJson.value?.email;
});

async function logout() {
  await $auth0.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
}

const callbackUrl = ref(null);
const router = useRouter();
const route = useRoute();
onMounted(() => {
  const split = window.location.href.split("?");
  if (split.length <= 1) {
    callbackUrl.value = null;
    return;
  }

  const paramString = split[1];
  let params = new URLSearchParams(paramString);
  for (let pair of params.entries()) {
    if (pair[0] === "callback") {
      callbackUrl.value = pair[1];
    } else {
      callbackUrl.value = null;
    }
  }
});

const callbackQuery = computed(() => {
  return callbackUrl.value ? { callback: callbackUrl.value } : {};
});

function gotoContact() {
  router.push({ path: "/contact", query: callbackQuery.value });
}

function gotoDealer() {
  router.push({ path: "/dealer", query: callbackQuery.value });
}

function gotoIdentities() {
  router.push({ path: "/identities", query: callbackQuery.value });
}

function gotoValidationAdmin() {
  router.push({ path: "/validation-admin", query: callbackQuery.value });
}

function gotoAuth0Admin() {
  router.push({ path: "/auth0-admin", query: callbackQuery.value });
}

const isNested = computed(() => {
  return route.matched.length > 1;
});

const nestedRoute = computed(() => {
  return route.matched.length > 1 ? route.matched[1] : null;
});

function exitInAppBrowser() {
  if (route.query?.callback) {
    const winProxy = window.open(new URL(route.query?.callback), "_top");
    winProxy.close();
  } else if (callbackUrl.value) {
    window.location = callbackUrl.value;
  } else {
    window.open("", "_self").close();
  }
}

// Transfer ownership

// Link social accounts

// Change password

// Delete account
</script>

<style lang="css" scoped>
.home-top-div {
  background: var(--bg1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - (2 * var(--padding)));
  height: calc(100% - (2 * var(--padding)));
  padding: var(--padding);
  border: 2px solid var(--bg0);
  box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.1);
  overflow: auto;
  /* transform: perspective(800px) matrix3d(1, -0.2, -0.2, 0, 0, 1, -0.2, 0, 0, 0, 1, -0.95, 0, 0, 0, 1); */

  /* background-color: red; */
  /* background-image: url('/topography.svg');  */
  /* filter: grayscale(100%) contrast(100%) brightness(180%); */
  /* border-radius: 50% 50% 50% 50%; */
  /* transform: perspective(1000px) scale(1.2) translateY(-50px) rotateX(45deg); */
  /* Outer glow */
}

.home-top-div:hover {
  box-shadow: 0px 6px 30px rgba(255, 255, 255, 0.3); 
  transition: box-shadow 0.3s ease-in-out, border 0.8s ease-in-out;
  border: 2px solid var(--bg1);
  /* transform: perspective(500px)  rotateX(0.5deg) translateZ(1.5rem);
  transition: transform 0.3s ease-in-out; */
}

@keyframes CompanyLogoColorSwitch {
  0% {
    filter: drop-shadow(20px 20px 0 rgba(0, 0, 0, 0.5));
  }

  25% {
    filter: drop-shadow(20px 10px 0 rgba(0, 0, 0, 0.5));
  }

  50% {
    filter: drop-shadow(-10px 20px 0 rgba(0, 0, 0, 0.5));
  }

  75% {
    filter: drop-shadow(0 20px 0 rgba(0, 0, 0, 0.5));
  }

  100% {
    filter: drop-shadow(10px 20px 0 rgba(0, 0, 0, 0.5));
  }
}

.admin-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0 var(--padding);
}

.admin-container span {
  color: var(--fg1);
}

.admin-container a {
  text-decoration-color: var(--fg0);
}


/* nav .right {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
}

.mobile-back-bttn {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
} */
.mobile-back-bttn button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--padding);
}

.company-logo {
  display: none;
}

@media (min-width: 800px) and (min-height: 800px) {
  .company-logo {
    display: block;
  }

  .home-top-div {
    min-width: 50vw;
    min-height: 50vh;
    width: initial;
    height: initial;
  }
}

button.admin {
  background: #F27935;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: transparent;
  position: relative;
  column-gap: var(--padding);
}

nav a {
  opacity: 1;
  animation: FadeIn 0.15s ease-in-out;
  color: var(--fg1);
}

.router-link-exact-active {
  font-style: italic;
  text-decoration: none;
  cursor: default;
}

.profile-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--fg0);
  transition: all 0.15s ease-in-out;
  flex: 1;
}

.profile-summary.nested {
  flex-direction: row !important;
  justify-content: flex-start !important;
  width: 100%;
}

.profile-summary img {
  width: 4rem;
  height: 4rem;
  transition: all 0.25s ease-in-out;
}

.profile-summary.nested img {
  width: 2rem;
  height: 2rem;
}

.profile-buttons {
  flex: 1;
  padding: var(--padding);
  background-color: var(--bg1);
  margin-top: calc(var(--padding) * 2);
  column-gap: calc(2 * var(--padding));
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: calc(2 * var(--padding));
  justify-content: center;
  align-items: center;
}

button:hover {
  cursor: pointer;
}
</style>
