<template>
  <Loading title="Unlinking..." v-if="loading" />
  <div :class="cardClasses">
    <div class="row">
      <img :src="picture" class="user-picture" />
      <div>{{ userIdentifier }}</div>
      <div class="user-id" @click="copyUserId">
        {{ userId }}
      </div>
    </div>

    <div class="identities mt-2" v-if="identities.length">
      <auth0-user
        v-for="identity in identities"
        :key="identity.key"
        :user="identity"
        @changed="emitChanged"
        :parent="user"
      ></auth0-user>
    </div>
    <div class="row">
      <a v-if="linkable" @click.stop="linkTo" href="#">Link to...</a>
      <!-- <a v-if="phoneAddable" @click.stop="addPhoneLink" href="#"
        >Add Phone...</a> -->
          </div>
    <div class="row mt-2" v-if="isChild">
      <a @click.stop="unlink" href="#">Unlink from Parent</a>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useAdminApi } from "../plugins/api";
import { useAuth0 } from "@auth0/auth0-vue";

const $auth0 = useAuth0();
const $adminApi = useAdminApi();
const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  parent: {
    type: Object,
    required: false,
  },
});

const emit = defineEmits(["changed"]);
const loading = ref(false);

const userIdentifier = computed(() => {
  const user = props.user;

  if (user.email?.length) {
    return user.email;
  }
  if (user.profileData?.email?.length) {
    return user.profileData.email;
  }

  if (user?.phone_number?.length) {
    return user.phone_number;
  }

  if (user?.profileData?.phone_number?.length) {
    return user.profileData.phone_number;
  }

  return "N/A";
});

const cardClasses = computed(() => (props.parent ? "card child" : "card"));
const isChild = computed(() => (props.parent ? true : false));
const linkable = computed(
  () => identities?.value?.length === 0 && !isChild.value
);
// const phoneAddable = computed(() =>
//   props.user.user_id.startsWith("sms") ? false : !isChild.value
// );
const identities = computed(() => {
  const ret =
    props.user?.identities?.filter(
      (i) => `${i.provider}|${i.user_id}` !== props.user.user_id
    ) || [];

  ret.forEach((i, index) => {
    i.key = `${i.provider}.${i.user_id}.${index}`;
  });

  return ret;
});

const userId = computed(() =>
  isChild.value
    ? props.user.provider + "|" + props.user.user_id
    : props.user.user_id
);
const picture = computed(
  () =>
    props.user.picture ||
    props.user?.profileData?.picture ||
    props.parent.picture
);

async function linkTo() {
  const to = prompt("Enter the parent Auth0 Account Id");
  if (!to) {
    return;
  }

  await $adminApi.postAuth0Identity(to, props.user.user_id);

  emit("changed");
}

//TODO: update user phone #
async function unlink() {
  loading.value = true;
  await $adminApi.deleteAuth0Identity(
    props.parent.user_id,
    props.user.provider,
    props.user.user_id
  );

  await $auth0.getAccessTokenSilently({ cacheMode: "off" });
  loading.value = false;

  emit("changed");
}
// async function addPhoneLink() {
//   const phone = prompt("Enter the Phone (e.g. +15012223333)");
//   if (!phone) {
//     return;
//   }

//   const json = {
//     phone_number: phone,
//     user_metadata: {},
//     blocked: false,
//     email_verified: false,
//     phone_verified: true,
//     app_metadata: {},
//     given_name: props.user.given_name,
//     family_name: props.user.family_name,
//     name: props.user.name,
//     nickname: props.user.nickname,
//     connection: "sms",
//     verify_email: false,
//     username: props.user.username,
//     picture: "https://cdn.auth0.com/avatars/1.png",
//   };

//   await $adminApi.postAuth0User(json, { parentId: props.user.user_id });

//   emit("changed");
// }
function emitChanged() {
  emit("changed");
}
function copyUserId() {
  navigator.clipboard.writeText(props.user.user_id);
}
</script>

<style lang="css" scoped>
.card {
  background: var(--bg2);
  display: flex;
  flex-direction: column;
  row-gap: 0em;
  padding: var(--padding);
  color: var(--fg2);
}

.card.child {
  background-color: var(--bg1);
  color: var(--fg1);
}

a {
  font-style: italic;
  color: var(--fg1);
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: var(--padding);
  align-items: center;
  align-content: center;
}

.user-id {
  font-style: italic;
  font-size: smaller;
  justify-self: flex-end;
  text-align: right;
  flex-grow: 1;
  cursor: pointer;
  text-decoration: underline;
}

.user-picture {
  width: 2em;
  height: 2em;
}

.identities {
  margin-left: var(--padding);
  overflow: auto;
}
</style>
