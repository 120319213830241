<template>
  <div>
    <a
      v-show="!shown"
      @click.stop="show"
      class="subtle unvisitable"
      title="Other Account Options"
      >Other Account Options</a
    >

    <div v-show="shown" class="mt-5">
      <div v-show="!promptForEmail">
        <div>
          <a
            @click.stop="beginDeleteAccount"
            class="subtle unvisitable"
            title="Permanently Delete Account"
            >Delete Account...</a
          >
        </div>
        <div>
          <label class="tinylabel">
            (All Pipe Planner and Plastic Collections Data Related to Your
            Account Will Be Permanently Removed -- Including Pending
            Rebates)</label
          >
        </div>
      </div>

      <div v-show="promptForEmail" id="verify_div">
        <div style="display: 'flex'">
          <label
            for="verify_email"
            v-html="
              `Please type <strong>${props.verificationValue}</strong> to confirm`
            "
          ></label>
          <br />
          <input
            id="verify_email"
            class="mt-1"
            name="email"
            type="email"
            v-model="validationEmail"
            size="20"
            autocomplete="off"
          />
        </div>

        <div class="mt-1" style="display: 'flex'">
          <input
            type="button"
            class="cancel"
            @click="cancelDelete"
            value="Cancel"
          />
          <input
            type="submit"
            class="ml-1"
            :disabled="!finalDeleteEnabled"
            @click="finalDelete"
            value="Delete"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useApi } from "../plugins/api";
const $api = useApi();

const shown = ref(false);
const promptForEmail = ref(false);
const validationEmail = ref("");

const props = defineProps({
  verificationValue: { type: String, required: false },
});

const finalDeleteEnabled = computed(
  () => validationEmail.value === props?.verificationValue
);

function show() {
  shown.value = true;
}

function beginDeleteAccount() {
  promptForEmail.value = true;
}
async function finalDelete() {
  await $api.deleteUser();

  alert("Your Account Has Been Permanently Removed");

  window.location = "https://revolutioncompany.com";
}

function cancelDelete() {
  shown.value = false;
  promptForEmail.value = false;
  validationEmail.value = "";
}
</script>

<style scoped>
a{
  cursor: pointer;
  text-decoration: underline;
}
#verify_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#verify_email {
  width: 10em;
}

.cancel {
  background: orange !important;
}

.tinylabel {
  font-size: x-small;
  font-style: italic;
}
</style>
