<template>
  <span>
    <template v-if="editing">
      <select v-if="options" @change="submit" v-model="value">
        <option v-for="option in options" :key="option.id" :value="option.id">
          {{ option.name }}
        </option>
      </select>
      <input
        v-else
        v-show="editing"
        v-model="value"
        @keyup.enter="submit"
        @blur="submit"
        v-bind="inputProps"
        ref="theInput"
      />
    </template>
    <label v-show="!editing" class="thelabel" @click="edit">{{ label }}</label>
  </span>
</template>

<script setup>
import { computed, ref } from "vue";
const props = defineProps({
  inputProps: {
    type: Object,
    required: false,
    default: () => ({ type: "text" }),
  },
  options: {
    type: Array,
    required: false,
    default: null,
  },
});

const value = defineModel();
const emit = defineEmits(["change"]);

const editing = ref(false);
const theInput = ref(null);

const label = computed(() => {
  if (props.options) {
    const item = props.options.find((option) => option.id === value.value);
    return item.name;
  }
  return value.value?.length ? value.value : "...";
});
//focus input when clicked on
function edit() {
  editing.value = true;
  requestAnimationFrame(() => {
    if (theInput.value) {
      theInput.value.focus();
    }
  });
}

function submit() {
  editing.value = false;
  emit("change", value.value);
}
</script>

<style lang="css" scoped>
input:focus{
    outline: none;
}
.thelabel {
  cursor: pointer;
}

.thelabel:hover {
  text-decoration: underline;
}
</style>
