<template>
  <div class="outer">
    <div class="far-edges i mb-1">
      <div>{{ friendlyIdentifier }}</div>
      <div>{{ userId }}</div>
    </div>

    <div>
      <label class="val-label">Address 1</label>
      <editable-input v-model="userData.address" @change="change" />
    </div>

    <div>
      <label class="val-label">Address 2</label>
      <editable-input v-model="userData.address2" @change="change" />
    </div>

    <div>
      <label class="val-label">City</label>
      <editable-input v-model="userData.city" @change="change" />
    </div>

    <div>
      <label class="val-label">First Name</label>
      <editable-input v-model="userData.firstName" @change="change" />
    </div>

    <div>
      <label class="val-label">Last Name</label>
      <editable-input v-model="userData.lastName" @change="change" />
    </div>

    <div>
      <label class="val-label">Organization</label>
      <editable-input v-model="userData.org" @change="change" />
    </div>

    <div>
      <label class="val-label">State</label>
      <editable-input v-model="userData.state" @change="change" />
    </div>

    <div>
      <label class="val-label">Zip</label>
      <editable-input
        v-model="userData.zip"
        @change="change"
        :inputProps="{
          type: 'text',
          size: 5,
          required: true,
          pattern: '^\d{5}(-\d{4})?$',
        }"
      />
    </div>

    <div>
      <label class="val-label">Dealer</label>
      <editable-input
        v-model="userData.dealerId"
        @change="change"
        :options="dealers"
      />
    </div>
  </div>
</template>

<script setup>
import EditableInput from "@/components/EditableInput.vue";
import { reactive, computed, onBeforeMount } from "vue";
import { useApi } from "../plugins/api";

const $api = useApi();

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
  states: { type: Array, required: true },
  dealers: { type: Array, required: true },
});

const userData = reactive({
  address: "",
  address2: "",
  city: "",
  firstName: "",
  lastName: "",
  org: "",
  phone: "",
  state: "",
  zip: "",
  dealerId: "",
});

const userId = computed(() => props.info.auth0_user_id);
const selectedDealer = computed(() =>
  props.dealers.find((d) => d.id === props.info.dealer_id)
);
const friendlyIdentifier = computed(() => {
  let ret = `${props.info.email || ""}`;

  if (props.info.phone) {
    if (ret.length) {
      ret += " / " + props.info.phone;
    } else {
      ret = props.info.phone;
    }
  }

  return ret;
});

async function change() {
  try {
    await $api.putUserValidation(props.info.id, {
      address: userData.address,
      address2: userData.address2,
      city: userData.city,
      firstName: userData.firstName,
      lastName: userData.lastName,
      org: userData.org,
      phone: props.info.phone,
      state: userData.state,
      zip: userData.zip,
      dealerId: userData.dealerId,
    });
  } catch (e) {
    console.error(e);
  }
}

onBeforeMount(() => {
  userData.address = props.info.address1;
  userData.address2 = props.info.address2;
  userData.city = props.info.city;
  userData.firstName = props.info.first_name;
  userData.lastName = props.info.last_name;
  userData.org = props.info.organization_name;
  userData.phone = props.info.phone;
  userData.state = props.info.state;
  userData.zip = props.info.zip;
  userData.dealerId = props.info.dealer_id;
});
</script>

<style lang="css" scoped>
.outer {
  display: flex;
  flex-direction: column;
  border: var(--padding);
  background-color: var(--bg2);
  padding: var(--padding);
}

.far-edges {
  display: flex;
  flex-direction: column;
}

.i {
  font-style: italic;
}

.val-label {
  min-width: 10em;
  display: inline-block;
  font-style: italic;
  margin-right: var(--padding);
}
</style>
