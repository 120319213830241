<template>
    <div class="login-top-div">
        <CompanyLogo class="company-logo" />

        <div class="contact-note mb-2">
            Support: <a href="tel:+15014900395">(501) 490-0395</a>
        </div>

        <div class="buttons-div">
            <button @click="loginWithSms">Login with Phone Number</button>
            <button @click="loginWithPasswordSocial">Password/Social Login</button>
            <button @click="signup" class="signup">Signup</button>
        </div>
    </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { useAuth0 } from '@auth0/auth0-vue';

import CompanyLogo from "../components/CompanyLogo.vue";

const $auth0 = useAuth0()

const route = useRoute()

async function loginWithSms() {
    const redirectUri = route.query.redirect_uri || window.location.origin

    await $auth0.loginWithRedirect({
        authorizationParams: {
            ...route.query,
            redirect_uri: redirectUri,
            connection: 'sms'
        }
    })
}

async function loginWithPasswordSocial() {
    const redirectUri = route.query.redirect_uri || window.location.origin
  

    await $auth0.loginWithRedirect({
        authorizationParams: {
            ...route.query,
            redirect_uri: redirectUri
        }
    })
}

async function signup() {
    const redirectUri = route.query.redirect_uri || window.location.origin

    await $auth0.loginWithRedirect({
        authorizationParams: {
            ...route.query,
            screen_hint: "signup",
            prompt: 'login',
            redirect_uri: redirectUri
        }
    })
}
</script>

<style lang="css" scoped>
/* Stolen from HomeView... */
.login-top-div {
    background: var(--bg1);
    display: flex;
    flex-direction: column;
    row-gap: var(--padding);
    column-gap: var(--padding);
    justify-content: center;
    align-items: center;
    column-gap: var(--padding);
    width: calc(100% - var(--padding));
    height: calc(100% - var(--padding));
    padding: var(--padding);
    border: 2px solid var(--bg0);
    box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.1);
    overflow: auto;
    color: var(--fg0);
    position: relative;
}

@media (min-width: 800px) and (min-height: 800px) {
    .login-top-div {
        min-width: 50vw;
        min-height: 50vh;
        width: initial;
        height: initial;
    }
}

.company-logo {
    max-width: 80vw;
}

.buttons-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: calc(3 * var(--padding));
    min-width: 50vw;
}

.buttons-div button {
    width: 17em;
}

button.signup {
    background: var(--deltaGreen);
}

.contact-note {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 5rem;
}
</style>
