<template>
    <div class="loading-top">
        <div class="loading" />
        <label>{{ props.title }}</label>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: false,
        default: "Loading..."
    }
})
</script>

<style lang="css" scoped>
.loading-top {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    /* width: 100%;
    height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    row-gap: var(--padding);
    font-style: italic;
    background: rgba(0, 0, 0, 0.75);
}

.loading {
    color: white;
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid var(--revBlue);
    /* Blue */
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 7s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>