<template>
    <Loading title="Logging Out..." />
</template>

<script setup>
import { useAuth0 } from '@auth0/auth0-vue';
import { onMounted } from 'vue';

const $auth0 = useAuth0()

onMounted(() => {
    $auth0.logout({
        logoutParams: {
            returnTo: window.location.origin,
        }
    })
})
</script>

<style lang="css" scoped></style>