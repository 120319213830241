<template>
  <div class="review-container">
    <div class="section-header">
      <h2>My Information</h2>
      <span @click="setStep(0)">Edit</span>
    </div>
    <div class="blue-line"></div>
    <div class="value-container">
      <strong>First Name:</strong>
      <span>{{ userFormData.firstName }}</span>
    </div>
    <div class="value-container">
      <strong>Last Name:</strong>
      <span>{{ userFormData.lastName }}</span>
    </div>
    <div class="value-container">
      <strong>Address:</strong>
      <span>{{ userFormData.address }}</span>
    </div>
    <div class="value-container">
      <strong>City:</strong>
      <span>{{ userFormData.city }}</span>
    </div>
    <div class="value-container">
      <strong>State: </strong>
      <span>{{ userFormData.state }}</span>
    </div>
    <div class="value-container">
      <strong>Organization:</strong>
      <span>{{ userFormData.org }}</span>
    </div>
    <div class="section-header">
      <h2>My Dealer</h2>
      <span @click="setStep(1)">Edit</span>
    </div>
    <div class="blue-line"></div>

    <div class="value-container">
      <strong>Name:</strong>
      <span>{{ myDealer?.value?.name ?? "" }}</span>
    </div>
    <div class="value-container">
      <strong>Address:</strong>
      <span>
        {{
          `${myDealer?.value?.address ?? ""} ${myDealer?.value?.city ?? ""} ${
            myDealer?.value?.zip5 ?? ""
          } `
        }}</span
      >
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import useSwrv from "swrv";
const props = defineProps({
  userFormData: {
    type: Object,
    required: true,
  },
  setStep: {
    type: Function,
    required: true,
  },
});

async function JsonFetcher(...params) {
  if (!params[0]?.length) {
    return null;
  }

  const res = await fetch(...params);
  return await res.json();
}

const dealerUrl = computed(() => {
  return `/api/dealers/${props.userFormData.dealerId}`;
});

const dealerSwrv = useSwrv(dealerUrl.value, JsonFetcher, {
  revalidateOnFocus: false,
});
const myDealer = computed(() => dealerSwrv.data || null);
</script>
<style scoped>
.review-container {
  width: 23rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem 0;
}
.section-header {
  display: flex;
  align-items: center;
  gap: 0 1rem;
}

.value-container {
  display: flex;
  gap: 0 0.5rem;
}
.section-header span {
  cursor: pointer;
  text-decoration: underline;
  color: var(--fg0)
}
.section-header span:hover {
  text-decoration: none;
}
h2 {
  margin: 0.5rem 0;
}
.blue-line {
  height: 2px;
  width: 100%;
  background-color: var(--revBlue);
}
</style>
